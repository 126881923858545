.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
 
}

.categories {
  display: grid;
  gap: 20px; 
}

.category {
  margin-bottom: 40px;
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 20px; 
}

.product {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.product img {
  max-width: 100%;
  height: auto;
}

.buttons {
  margin-bottom: 20px;
  margin-top: 20px;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 
}

.buttons {
  margin-top: 10px; 
  margin-right: auto;
}

.buttons button {
  margin-right: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.buttons button:hover {
  background-color: #fefcfc;
  color: black;
}

.buttons button:last-child {
  margin-right: 0;
}

input[type="text"] {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: auto;
  
}

input[type="text"]:focus {
  outline: none;
  border-color: black;
}

.cart {
  display: flex;
  justify-content: flex-end; 
  align-items: center; 
  padding: 0.5rem; 
  margin-left: auto;
  cursor: pointer;
}

.cart span {
  margin-left: 0.5rem; 
}

.cart i {
  font-size: 1.5rem; 
}

.cart-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  background-color: ghostwhite;
}

.cart-item {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.cart-item img {
  max-width: 100%;
  height: auto;
}

.item-details {
  margin-top: 10px;
}
.total-price {
  margin-top: 20px;
  text-align: right;
  padding: 30px;
  background-color: rgb(249, 230, 230);
}

@media screen and (max-width: 767px) {
  .total-price {
    padding: 20px; 
  }
}

@media screen and (max-width: 480px) {
  .total-price {
    padding: 10px; 
  }
}

button {
  background-color: black; 
  color: white; 
  padding: 8px 16px; 
  border: none; 
  border-radius: 4px; 
  cursor: pointer; 
}

button:hover {
  background-color: red; 
}

.decrease-btn,
.increase-btn {
  background-color: white; 
  color: black; 
  padding: 10px 20px; 
  border: none; 
  cursor: pointer; 
}

.decrease-btn:hover,
.increase-btn:hover {
  background-color: rgba(230, 227, 227, 0.853);
}

.cart-quantity {
  background-color: red; 
  color: white; 
  padding: 4px 8px; 
  border-radius: 50%; 
  font-size: 12px; 
  position: absolute; 
  top: 130px;
  right: 150px; 
}

.cart-quantity {
  background-color: red; 
  color: white; 
  padding: 4px 8px; 
  border-radius: 50%; 
  font-size: 12px; 
  position: absolute; 
  top: 130px;
  right: 150px; 
}

@media (max-width: 768px) {
  .cart-quantity {
    padding: 3px 6px; 
    font-size: 10px; 
    top: 160px;
    right: 20px; 
    
  }
}

@media (max-width: 430px) {
  .cart-quantity {
    padding: 3px 6px; 
    font-size: 10px; 
    top: 160px;
    right: 20px; 
    
  }
}